exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-policy-js": () => import("./../../../src/pages/cookie-policy.js" /* webpackChunkName: "component---src-pages-cookie-policy-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-offer-health-practitioners-js": () => import("./../../../src/pages/offer/health-practitioners.js" /* webpackChunkName: "component---src-pages-offer-health-practitioners-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-domains-hosting-js": () => import("./../../../src/pages/services/domains-hosting.js" /* webpackChunkName: "component---src-pages-services-domains-hosting-js" */),
  "component---src-pages-services-index-js": () => import("./../../../src/pages/services/index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-services-search-engine-optimisation-js": () => import("./../../../src/pages/services/search-engine-optimisation.js" /* webpackChunkName: "component---src-pages-services-search-engine-optimisation-js" */),
  "component---src-pages-services-upgrade-your-website-technology-js": () => import("./../../../src/pages/services/upgrade-your-website-technology.js" /* webpackChunkName: "component---src-pages-services-upgrade-your-website-technology-js" */),
  "component---src-pages-services-user-experience-website-review-js": () => import("./../../../src/pages/services/user-experience-website-review.js" /* webpackChunkName: "component---src-pages-services-user-experience-website-review-js" */),
  "component---src-pages-services-website-accessibility-review-js": () => import("./../../../src/pages/services/website-accessibility-review.js" /* webpackChunkName: "component---src-pages-services-website-accessibility-review-js" */),
  "component---src-pages-services-website-analytics-js": () => import("./../../../src/pages/services/website-analytics.js" /* webpackChunkName: "component---src-pages-services-website-analytics-js" */),
  "component---src-pages-services-website-audit-js": () => import("./../../../src/pages/services/website-audit.js" /* webpackChunkName: "component---src-pages-services-website-audit-js" */),
  "component---src-pages-services-website-design-js": () => import("./../../../src/pages/services/website-design.js" /* webpackChunkName: "component---src-pages-services-website-design-js" */),
  "component---src-pages-services-website-speed-performance-js": () => import("./../../../src/pages/services/website-speed-performance.js" /* webpackChunkName: "component---src-pages-services-website-speed-performance-js" */),
  "component---src-pages-services-wordpress-index-js": () => import("./../../../src/pages/services/wordpress/index.js" /* webpackChunkName: "component---src-pages-services-wordpress-index-js" */),
  "component---src-pages-services-wordpress-support-maintenance-js": () => import("./../../../src/pages/services/wordpress/support-maintenance.js" /* webpackChunkName: "component---src-pages-services-wordpress-support-maintenance-js" */),
  "component---src-pages-services-wordpress-website-design-js": () => import("./../../../src/pages/services/wordpress/website-design.js" /* webpackChunkName: "component---src-pages-services-wordpress-website-design-js" */),
  "component---src-pages-services-wordpress-website-development-js": () => import("./../../../src/pages/services/wordpress/website-development.js" /* webpackChunkName: "component---src-pages-services-wordpress-website-development-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-templates-blog-post-archive-js": () => import("./../../../src/templates/blog-post-archive.js" /* webpackChunkName: "component---src-templates-blog-post-archive-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

